<template>
  <div class="container-fluid natural-dispensary">
    <div class="row">
      <div class="col">
        <div class="card border-0 shadow-sm">
          <div class="card-header">
            <div class="col-auto my-auto d-flex align-items-center">
              <h5>Natural Dispensary Account Connection</h5>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col">
                <p v-if="!accountType" class="alert alert-primary mb-3">
                  <i class="far fa-info me-2"></i>Connect your Natural Dispensary
                  account to enable ordering there through Swandoola. Please enter
                  the email address used for your Natural Dispensary practitioner
                  account, this will be validated prior to ordering being enabled.
                </p>

                <p v-else class="alert alert-primary mb-3">
                  <i class="far fa-info me-2"></i>Your current Natural Dispensary
                  account type is: <b>{{ accountType | formatType }}</b>. You can
                  now proceed to order through the orders tab or link a different
                  account below.
                </p>

                <form @submit.prevent="addNdEmail">
                  <div class="row my-3 nd-verification-fields">
                    <div class="col my-auto">
                      <input
                        type="text"
                        v-model="emailAddress"
                        placeholder="Natural Dispensary Account Email Address"
                        class="form-control"
                      />
                    </div>
                    <div class="col ms-auto my-auto">
                      <button :disabled="loading" class="btn btn-primary">
                        <i class="far fa-check me-2"></i>Verify Account
                      </button>
                    </div>
                  </div>
                  <i>
                    Swandoola will automatically check periodically for account
                    changes.
                  </i>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: [],
    data() {
      return {
        emailAddress: this.$store.getters['auth/user'].nd_account_email
          ? this.$store.getters['auth/user'].nd_account_email
          : "",
        accountType: this.$store.getters['auth/user'].nd_account_type
          ? this.$store.getters['auth/user'].nd_account_type
          : null,
        loading: false
      };
    },
    computed: {
      path: function () {
        return this.$router.currentRoute.fullPath;
      },
      user() {
        return this.$store.getters['auth/user'];
      },
    },
    methods: {
      addNdEmail() {
        this.loading = true;

        this.$axios.post(
          process.env.VUE_APP_API_URL +
            "/settings/natural-dispensary/add-email",
          {
            email: this.emailAddress,
          }
        )
        .then(({ data }) => {
          this.accountType = data.nd_account_type;

          this.$store.getters['auth/user'].nd_account_email = data.nd_account_email;
          this.$store.getters['auth/user'].nd_account_type = data.nd_account_type;

          this.loading = false;
          this.$EventBus.$emit("alert", data);
        });
      },
    },
    filters: {
      formatType(type) {
        return type.replace(/_/g, " ");
      },
    },
  };
</script>

<style>
  @media (max-width: 768px) {
    .nd-verification-fields {
      flex-direction: column;
    }

    .nd-verification-fields > div > button {
      width: 100%;
      margin-top: 0.5rem;
    }
  }
</style>
